import React from "react"

import AboutMe from "../components/about-me"
import Banner from "../components/banner"
import Hero from "../components/hero"
import FeatureList from "../components/feature-list"
import SEO from "../components/seo"

const ThanksPage = () => (
  <>
    <SEO title="Thank you for confirming your email" />
    <Banner />
    <Hero />
    <FeatureList />
    <AboutMe />
  </>
)

export default ThanksPage
