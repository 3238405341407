import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "./link"

const AboutMe = () => {
  const data = useStaticQuery(graphql`
    query ProfileImage {
      file(relativePath: { eq: "gv-photo.jpeg" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="bg-white text-gray-900">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl lg:max-w-4xl mx-auto">
          <div className="flex flex-col lg:flex-row">
            <div className="mr-10 flex-shrink-0 self-center">
              <img
                className="mx-auto object-cover h-48 w-48 rounded-full"
                src={data.file.childImageSharp.fixed.src}
                alt="German Velasco"
              />
            </div>
            <div>
              <div className="">
                <div className="space-y-4">
                  <h3
                    id="about-me"
                    className="text-xl leading-9 font-extrabold tracking-tight sm:text-4xl"
                  >
                    About Me
                  </h3>
                  <div className="text-lg leading-6 font-medium">
                    <h4>German Velasco</h4>
                  </div>
                  <div className="text-lg leading-7 space-y-4">
                    <p className="text-gray-500">
                      I'm a software developer who's been practicing test-driven
                      development for almost a decade.
                    </p>
                    <p className="text-gray-500">
                      You might recognize me from my{" "}
                      <Link to="https://www.youtube.com/watch?v=h8NURVLysrk">
                        Testing LiveView ElixirConf talk
                      </Link>
                      , my{" "}
                      <Link to="https://www.tddphoenix.com">
                        TDD Phoenix book
                      </Link>
                      , or{" "}
                      <Link to="https://www.germanvelasco.com/blog">
                        one of my blog posts
                      </Link>
                      .
                    </p>
                    <p className="text-gray-500">
                      For the past five years, I've been helping clients deal
                      with slow test suites, intermittent failures, difficult
                      test cases, and more.
                    </p>
                    <p className="text-gray-500">
                      <span className="font-semibold">
                        Testing is my passion.
                      </span>{" "}
                      I love thinking about it, iterating on practices, and
                      figuring out how to write tests that are valuable, stable,
                      and easy to maintain.
                    </p>
                  </div>
                  <ul className="flex space-x-5">
                    <li>
                      <Link to="https://twitter.com/germsvel">
                        <span className="sr-only">Twitter</span>
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe
