import React from "react"

const ListItem = ({ children }) => (
  <div className="mt-4 flex space-x-3">
    <svg
      className="flex-shrink-0 h-6 w-6 text-green-500"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      />
    </svg>
    <div className="space-y-2">
      <dt className="text-lg leading-6 text-gray-800">{children}</dt>
    </div>
  </div>
)

export default ListItem
