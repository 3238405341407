import React from "react"

const Hero = () => (
  <div className="relative bg-gray-900 overflow-hidden">
    <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
      <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-12">
            <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-white sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
              Testing <span className="text-orange-500"> LiveView</span>
            </h2>
            <p className="mt-3 text-xl text-gray-100 sm:mt-5 sm:text-2xl lg:text-xl xl:text-2xl">
              Learn to test LiveView effectively. Gain trust in your code. Ship
              confidently.
            </p>
            <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center">
              <a
                href="https://courses.germanvelasco.com/testing-liveview/buy"
                className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-600 shadow-sm hover:bg-orange-700 focus:outline-none focus:border-orange-700 focus:ring-orange active:bg-orange-800 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
              >
                Buy Course
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
)

export default Hero
