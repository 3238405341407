import React from "react"

import ListItem from "./list-item"

const FeatureList = () => (
  <>
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
            What you'll learn
          </h2>
          <p className="mt-4 text-lg leading-7 text-gray-500">
            We'll cover everything you need to know. From fundamentals to
            mastery.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
          <div className="space-y-2">
            <h3 className="text-center text-lg leading-7 font-medium text-gray-900 pb-2">
              The Fundamentals
            </h3>
            <ListItem>Setting up and basic testing of LiveViews</ListItem>
            <ListItem>Testing interactivity with LiveViews</ListItem>
            <ListItem>Testing function components</ListItem>
            <ListItem>
              Testing LiveComponents and what to test in a component instead of
              a LiveView
            </ListItem>
            <ListItem>Testing JavaScript hooks</ListItem>
            <ListItem>Testing uploads</ListItem>
          </div>
          <div className="space-y-2">
            <h3 className="text-center text-lg leading-7 font-medium text-gray-900 pb-4">
              The Abstract Hard Topics
            </h3>
            <ListItem>
              How to write stable tests. We'll walk through the thought process
              of choosing what to test and where to test it.
            </ListItem>
            <ListItem>
              Testing event-driven UIs in LiveView, and how to reason about
              testing a process-backed UI.
            </ListItem>
            <ListItem>
              Writing tests in the domain of your application, so that your team
              can actually understand them.
            </ListItem>
            <ListItem>And much more!</ListItem>
          </div>
        </dl>
      </div>
    </div>
  </>
)

export default FeatureList
